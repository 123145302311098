.why-us-container {
  gap: 6rem;
}

.why-us-img{
  width: 50%;
  // background-image: url('../../../public/images/why-us.jpg');
  @include bg-cover;
}

.why-us-content {
  color: var(--secondary-color);
  gap: 4rem;
  @include padding-y(6rem, 6rem); 
}

.why-us-heading {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.why-us-cards {
  color: var(--secondary-color);
  display: grid;
  grid-template-rows: 1fr 1fr;
  @include grid-cols(2, 1fr, 6rem);
}

.why-card-content {
  display: grid;
  gap: 1rem;

  p {
    opacity: 0.7;
  }
}

@include devices(lg) {
  .why-us-cards {
    grid-template-rows: 2;
    @include grid-cols(2, 1fr, 4rem);
    @include padding-x(4rem, 4rem);
  }
}

@include devices(md) {
  .why-us-cards {
    grid-template-columns: 1fr;
    gap: 3rem;
    @include padding-x(3rem, 3rem);
  }

  .why-us-heading {
    @include padding-x(3rem, 3rem); 
  }
}