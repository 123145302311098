.hero {

  &-heading {
    color: var(--secondary-color);
  }

  &-content {
    gap: 4rem;
    max-width: 50vw;
  }

  &-info {
    font-size: 9rem;
    font-weight: 600;
    line-height: 1;
    color: var(--secondary-color);  
  }
}

@include devices(md) {
  .hero-content {
    font-size: 5rem;
  }
}

@include devices(sm) {
  .hero-content {
    max-width: 80vw;
    @include padding-x(4vw, 4vw);
  }

  .hero-info h2{
    font-size: 4rem;
  }
}