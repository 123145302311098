$nav-height: 6rem;
$nav-font-size: 1.2rem;



:root {
  --primary-color: #0C0C0C;
  --secondary-color: #e0eeee;
  --white-color: #ffffff;
  --black-color: #000000;
  --light-blue-color: #c6e4ff;

  --color-light-gray: #f3f3f3;
  --color-dark-gray: #979797;
  --color-light-green: #556b2f;
  --color-dark-green: #092E23;

  --color-text-dark: #545454;
  --color-text-light: #878787;

  --success-color: #0e9c00;

  --background-color-1 : #f3f3f3;
  --background-color-2 : #454D4B;

  --text-color-gray: #C0C0C0;

  --border-radius: .8rem;

  --gap-4: 4rem;
  --gap-6: 6rem;
}