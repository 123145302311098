// .root-container {
//   margin-top: $nav-height;
// }

.section {
  min-height: 100vh;
  @include padding-y(12rem, 12rem);
  @include padding-x(8vw, 8vw);
}

.input_box {
  position: relative;
  margin-top: 2.4rem;
}

.input_field {
  display: block;
  background: var(--background-color-1);
  width: 100%;
  height: 4.2rem !important;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  border: 0;
}

.input_field_textbox {
  display: block;
  background: var(--background-color-1);
  width: 100%;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
}

.input_label {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-text-light);
  line-height: 1;
  pointer-events: none;
  margin-bottom: 0.8rem;
}

.error {
  font-size: 1.2rem;
  color: var(--text-error-color);
}

.success {
  color: var(--success-color);
}

.text-dark-gray {
  color: var(--color-text-dark);
}

.text-light-gray {
  color: var(--color-text-light);
}

@include devices(xxl) {
  .section {
    @include padding-x(8vw, 8vw);
  }
}

@include devices(lg) {
  .section {
    @include padding-x(8vw, 8vw);
  }
}

@include devices(md) {
  .section {
    @include padding-x(6vw, 6vw);
  }
}

@include devices(sm) {
  .section {
    @include padding-x(4vw, 4vw);
  }
}
