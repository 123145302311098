.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.scroll-to-top button {
  color: var(--black-color);
  border-radius: 50%;
  font-size: 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  @include dimension(4rem, 4rem);
  @include bordered(1px, var(--black-color));
}

.scroll-to-top button:hover {
  background : #D5CABD;
}
