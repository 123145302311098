#prod-listing {
  @include padding-y(6rem, 6rem);
  @include padding-x(6rem, 6rem);
  min-height: calc(100vh - 8rem);
}

.prod-listing {

  &-wrapper {
    display: grid;
    @include grid-cols(3, 1fr, 3rem);
    margin-top: 3rem;
  }
}

.prod-listing-filters {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 1px;

  &-item {
    opacity: 0.7;
  }

  &-item.active {
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 0.8rem;
    text-decoration-thickness: 0.4rem;
  } 
}

@include devices(lg) {
  .prod-listing-wrapper {
    @include grid-cols(1, 1fr, 4rem);
    margin-top: 3rem;
  }
}

@include devices(md) {
  #prod-listing {
    @include padding-x(4rem, 4rem);
  }
}

@include devices(sm) {
  #prod-listing {
    @include padding-x(2rem, 2rem);
  }
}