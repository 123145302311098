/* InputWithButton.css */

.input-with-button {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  padding-right: 4rem;
  padding-left: 1rem; 
  font-size: 1.4rem;
  border-radius: 4px;
  border: 2px dashed var(--bs-border-color);
  box-sizing: border-box;
  @include padding-y(0.8rem, 0.8rem);
}

.input-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  padding: 0 15px;
  margin: 5px;
  color: #fff;
  background-color: var(--color-light-green);
  border: none;
  border-radius: 4px;
  font-size: 14px;
}

.input-button:hover {
  background-color: var(--primary-color);
}
