.about-vir-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
}

.about-vir-img{
  width: 100%;
  background-image: url('../../../public/images/about-vir.jpg');
  @include bg-cover;
}

.about-vir-content {
  display: grid;
  gap: 4rem;
  padding-right: 6rem;
  @include padding-y(6rem, 6rem); 

  p {
    opacity: 0.9;
  }

  &_top {
    gap: 4rem;
    display: flex;
    flex-direction: column;
  }
}

.about-vir-cards {
  display: grid;
  gap: 6rem;
}

@include devices(lg) {
  .about-vir-container {
    grid-template-columns: 1fr;
  }

  .about-vir-content {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .about-vir-cards {
    grid-template-rows: 2;
    @include grid-cols(2, 1fr, 4rem);
  }
}

@include devices(md) {
  .about-vir-cards {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}