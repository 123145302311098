.store {
  display: flex;
  flex-direction: row; /* Default for larger screens */

  &-sidebar {
    width: 400px;
    padding: 20px;
    background-color: #f4f4f4;
    max-height: 100vh; /* Keep the sidebar from growing too tall */
    overflow-y: auto; /* Allows scrolling on the sidebar */
  }

  &-search-bar {
    display: flex;
    justify-items: flex-end;
    justify-content: space-between;
    position: relative;
  
    & input {
      height: 4.2rem !important;
      padding: 0.8rem 1.6rem;
      font-size: 1.6rem;
      border: 0;
      width: 100%;
    }
  }

  &-search {
    padding: 0.8rem 1.6rem;
    letter-spacing: 4;
    font-size: 1.2rem;
    color: white;
    border: none;
  }

  &-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &-list li {
    padding: 16px;
    cursor: pointer;
  }

  &-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 15px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  &-name {
    font-size: 1.4rem;
    margin: 0;
    text-transform: uppercase;
  }

  &-address {
    font-size: 13px;
    color: #777;
    margin-top: 5px;
  }

  /* Mobile view adjustments */
  @media (max-width: 768px) {
    flex-direction: column; /* Stack the sidebar and map */
    height: 100vh;

    &-sidebar {
      width: 100%; /* Full width for the sidebar on small screens */
      padding: 15px; /* Reduce padding on mobile */
    }

    .map-container {
      flex-grow: 1;
      height: 40%; /* Adjust the height for map on mobile */
    }

    .store-list {
      margin-bottom: 20px;
    }

    /* Optional: Reduce font sizes and padding for mobile */
    .store-name {
      font-size: 1.2rem;
    }

    .store-address {
      font-size: 12px;
    }

    .store-search-bar input {
      font-size: 1.4rem;
    }

    .store-card {
      margin-bottom: 10px;
    }
  }

  /* Tablet View */
  @media (max-width: 1024px) {
    .store {
      flex-direction: column; /* Stack on tablets */
    }

    .store-sidebar {
      width: 100%; /* Full width for sidebar on tablets */
    }

    .map-container {
      flex-grow: 1;
      height: 50%; /* Map takes 50% of the height on tablets */
    }
  }
}

.search-wrapper {
  position: relative;
}

.search-wrapper input {
  width: 100%;
  padding-right: 30px; /* Space for the "X" */
}

.clear-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #888;
}

.store .search-bar input {
  width: 80%;
  padding: 8px;
}

.city-dropdown select {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
}

.store-card.selected {
  border: 2px solid var(--black-color);
}

.map-container {
  flex-grow: 1;
  height: 100vh;
}

/* InfoWindow Content Styles */
.info-window-content {
  background-color: #fff;
  border-radius: 8px;
  width: 280px;
  text-align: center;
}

.info-window-content h3 {
  margin: 0;
  font-size: 16px;
  color: var(--color-primary);
}

.info-window-content p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

/* Marker InfoWindow Close Button */
.google-map-info-window .gm-ui-hover-effect {
  display: none !important;
}

/* Customize the Map's Info Window's Arrow/Pointer */
.gm-style-iw {
  background-color: transparent;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.gm-style-iw-chr {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
}

.gm-style-iw.gm-style-iw-c {
  padding-top: 14px !important;
}
