.achievements {
  gap: 6rem;

  &-img-container {
    position: relative;
  }

  .achievements-content {
    gap: 6rem;

    &-heading {
      letter-spacing: 0;
      text-transform: none;
    }

    &-subHeading h2{
      margin-top: 2rem;
      font-size: 6rem;
    }

    &-text {
      max-width: 60rem;
      font-size: 1.6rem;
      font-style: italic;
      margin-top: 4rem;

      > p {
        margin-top: 2rem;
        line-height: 1.8;
      }
    }
  }
}

@include devices(md) {
  .achievements .achievements-content-subHeading h2 {
    font-size: 4rem;
  }
}