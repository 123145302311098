.contact-container {
  gap: 2rem;
}

.contact-description {
  width: 50vw;
}

.form-and-details {
  display: grid;
  @include grid-cols(2, 1fr, 6rem);
  @include padding-y(6rem, 6rem);
}

.form-wrap {
  flex: 1;

  & > h2 {
    font-size: 3.6rem;
  }
}

.contact-details {
  padding-bottom: 4rem;
  margin-bottom: 4rem;

  &:not(:last-child){
    border-bottom: 1px solid rgba(29, 29, 29, 0.15);
  }
}

@include devices(lg) {
  .form-and-details {
    @include grid-cols(1, 1fr, 6rem);
  }

  .contact-description {
    width: 80vw;
  }
}