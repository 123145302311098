.newsletter {
  position: relative;
  margin: 0 auto;

  &-img-container {
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      z-index: 80;
    }
  }

  &-caption {
    position: absolute;
    top: 8%;
    left: 8%;
    margin-bottom: 4rem;
    width: 50vw;
    z-index: 81;
    color: var(--secondary-color);

    > h2 {
      font-size: 6rem;
      color: var(--secondary-color);
    }

    .input-newsletter {
      width: 34rem;
      border-radius: 30rem;
    }
  }

  .sign-up-wrapper {
    max-width: 320px;

    > .input-with-button > .input {
      border: none !important;
      color: var(--color-text-dark);
    }
  }
}

@include devices(lg) {
  .newsletter-caption {
    width: 65vw;

    > h2 {
      font-size: 3.2rem
    }

    > p {
      font-size: 1.8rem;
    }
  }
}

@include devices(md) {
  .newsletter-caption {
    width: 70vw;

    > h2 {
      font-size: 2.1rem
    }

    > p {
      font-size: 1.4rem;
    }
  }
}
