#about-section {
  @include flex-center;
  background-color: #151414;
  // background-image: radial-gradient(circle, #d6d6d6, #cacaca, #bfbfbf, #b3b3b3, #a8a8a8);
}

#feature-section {
  @include flex-center;
}

#product-section {
  @include flex-center;
}

#why-us-section {    
  background: #16222A; 
  background: -webkit-linear-gradient(to left, #3A6073, #16222A);
  background: linear-gradient(to left, #3A6073, #16222A);
  // background-image: url('../../../public/why-us-bg.jpg');
  // @include bg-cover;

}

#testimonials {
  @include padding-y(12rem, 12rem);
  background-color: var(--white-color);
}

#faq-section {
  @include flex-center;
  background: linear-gradient(180deg,#fff,hsla(0,0%,100%,0) 15.59%),linear-gradient(90.32deg,#fdf5e0 -2.35%,#fff 45.56%,#e2f7f9 99.81%);
}

#footer {
  background-color: var(--black-color);
  @include padding-y(8rem, 3rem);
}

#leadership-section {
  background: var(--background-color-1);
  padding-top: 12rem;
  padding-bottom: 12rem;
  @include flex-center;
}