.custom-o-list {
  counter-reset: my-counter;
  list-style-type: none;
  padding: 0;
}

.privacy-policy-container {
  & > h1 {
    font-size: 8rem;
    margin-bottom: 4rem;
  }
}

.custom-list {
  margin-top: 4rem;
  font-size: 2rem;
  font-weight: 600;
  counter-increment: my-counter;
  margin-bottom: 10px;

  & > p {
    font-size: 1.6rem;
    font-weight: normal;
    margin-top: 1rem;
  }

  &::before {
    content: counter(my-counter) ".";
    font-weight: bold;
    margin-right: 5px;
  }
}