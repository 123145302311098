.about-container-wrapper {
  display: grid;
  grid-template-columns: .75fr 1.25fr;
  gap: 2rem;
  align-items: end;
}

.about-container {
  gap: 6rem;
  margin: 0 auto;
  margin-bottom: 6rem;
  align-self: center;
  
  &-heading {
    color: var(--white-color);

    .about-sub-heading h2{
      font-size: 8rem;
      font-weight: 600;
    }
  }

  &-text {
    font-size: 1.8rem;
    line-height: 2;
    text-align: left;
    gap: 4rem;

    &-content {
      color:  #949292;
      line-height: 1.4;
      max-width: 50ch;
    }
  }
}

.about-btn {
  color: var(--white-color);
  background-color: var(--black-color);
  border-color: var(--black-color);

  &:hover {
    color: var(--black-color);
    background-color: transparent;
    border-color: var(--black-color);
  }
}

.youtube-container {
  width: 80%; 
  margin: 0 auto;
}

@include devices(xl) {
  .about-container-heading {
    text-align: center !important;

    & > .about-sub-heading h2 {
      font-size: 8rem;
      text-align: center !important;   
    }
  }

  .about-container-text {
    width: 100%;
    font-size: 1.8rem;
    padding-left: 0;
  }

  .youtube-container {
    width: 100%;
  }
}

@include devices(xl) {
  .about-container-wrapper {
    grid-template-columns: 1fr;
  }
}

@include devices(lg) {
  .about-container {
    width: 100%;

    
    &-text-content {
      max-width: 80ch;
    }
  }
}

@include devices(md) {
  .about-container-heading .about-sub-heading h2{
    font-size: 4rem;
  }

  .about-container-text {
    font-size: 1.6rem;
  }
}