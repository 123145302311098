.prod-detail-wrapper {
  position: relative;
  height: calc(100vh - 18rem);
  padding: 2rem;
  overflow-y: scroll; /* Enable vertical scrollbar */
  scrollbar-width: none; /* Hide default scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide default scrollbar in IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Hide default scrollbar in Chrome, Safari, and Opera */
  }
}

.amount-card {
  position: absolute;  
  bottom: 0;
  width: 100%;
  @include padding-x(2rem, 2rem);
  
  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    border-top-left-radius: 3.2rem;
    border-top-right-radius: 3.2rem;
    background-color: #dbdbdb;
    }

    &-total,
    &-btn {
      display: flex;
      justify-content: space-between;
    }
}

.amount-btn {
  font-size: 1rem;
  padding: 0.4rem 0.8rem;
  color: var(--white-color);
  background-color: var(--black-color);
  border-color: var(--black-color);
}

.skin-header,
.range-header,
.accessories-header {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--black-color);
  margin-bottom: 2rem;
}

@include devices(lg) {
  .prod-detail-wrapper {
    height: auto;
    padding-bottom: 18rem;
  }
}
