.testimonial-content {
  gap: 4rem;
}

.testimonial-sub-heading,
.testimonial-heading {
  color: var(--secondary-color);
}

.customer-name {
  font-size: 1.4rem;
  font-weight: 500;
}

.customer-comment {
  font-size: 1.3rem;
}

.testimonial-container { 
  margin-top: 12rem;
  @include centered;
  @include padding-x(6rem, 6rem);
}

.customer-review {
  display: grid;
  @include grid-cols(3, 1fr, 3rem);
  align-items: flex-start;

  &-card {
    border-radius: 2rem;
    max-width: 32rem;
    padding: 2rem;
    background-color: #F8F9FC;

    &_top {
      gap: 4rem
    }
  }
}

.customer-img {
  @include dimension(6rem, 6rem);
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
  }
}

.review-btn {
  @include dimension(5.2rem, 3.6rem);
  border-radius: 2.4rem;
  background-color: var(--white-color);
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

@include devices(lg) {

  .customer-review {
    @include grid-cols(2, 1fr, 3rem);
  }

  .testimonial-container {
    @include padding-x(4rem, 4rem);
  }
}

@include devices(md) {
  .testimonial-content {
    gap: 2rem;
  }
  
  .customer-review {
    gap: 2rem;
    @include grid-cols(1, 1fr, 3rem);
  }

  .customer-name {
    font-size: 1.8rem;
  }
  
  .customer-comment {
    font-size: 1.4rem;
  }
  
  .testimonial-container {
    margin-top: 4rem;
    @include padding-x(2rem, 2rem);
  }
}

@include devices(sm) {
  .testimonial-content {
    gap: 1rem;
  }
}