#key-achievements-section {
  @include padding-y(12rem, 12rem);
}

.heroCommunity {
  position: relative;

  .img-container {
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      z-index: 80;
    }
  }

  .group-img {
    filter: grayscale(100%);
  }

  &-caption {
    position: absolute;
    top: 4%;
    left: 4%;
    margin-bottom: 4rem;
    width: 60vw;
    z-index: 81;

    > h2 {
      font-size: 4rem;
      color: var(--white-color);
    }

    > h3 {
      font-size: 2.4rem;
      color: var(--white-color);
    }
  }

  &-card {
    z-index: 82;
    position: absolute;
    right: 4%;
    bottom: 0;
    padding: 2rem;
    margin-bottom: 6rem;
    border-radius: 0.8rem;
    color: var(--black-color);
    background-color: var(--secondary-color);

    &-content {
      max-width: 34rem;

      > h4 {
        font-size: 3.6rem;
      }

      > p {
        font-size: 1.6rem;
        margin-top: 2rem;
      }
    }
  }
}

@include devices(lg) {
  .heroCommunity-caption{
    width: 70vw;

    > h2 {
      font-size: 3.2rem;
    }

    > h3 {
      font-size: 1.8rem;
    }
  }
}

@include devices(md) {
  #key-achievements-section {
    @include padding-x(4vw, 4vw);
  }

  .heroCommunity-caption{
    width: 80vw;

    > h2 {
      font-size: 2.1rem;
    }

    > h3 {
      font-size: 1.4rem;
    }
  }
}