.tr-container {
  height: calc(100vh - $nav-height);
  background-color: #f8f8f8;

  &-wrap {
    background-color: var(--white-color);
    padding: 2.4rem;
    border-radius: 2rem;
    max-width: 42rem;
  }

  &-message {
    max-width: 60rem;
    font-size: 1.6rem;
    color: var(--color-text-dark);
  }

  &-link {
    text-decoration: 'none';
    color: '#1e90ff';
    font-size: 1.2rem;
  }
}

.tr-btn {
  padding: 8px 16px;
  letter-spacing: 0;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
}

.tr-btn:hover {
  border: 1px solid #000
}