.multiple-selection-card {
  flex-direction: column;
  gap: 2rem;
}

.multiple-btn-wrapper {
  cursor: pointer;
  border-radius: 0.8rem;
}

.accessories-wrapper {
  @include flex(flex-start, stretch);
  gap: 1rem;
  padding: 1rem;

  .accessories-img {
    border-radius: 0.8rem;
    object-fit: contain;
  }

  .accessories-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    &-title {
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
      font-weight: 600;
    }

    &-text {
      font-size: 1.2rem;
    }
  }
}