*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  // scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

input,
button,
textarea {
  color: inherit;
  border: none;
  outline: none;
  font-family: inherit;
}

button {
  cursor: pointer;
}

textarea {
  resize: vertical;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

img,
svg {
  vertical-align: middle;
  user-select: none;
}

figure {
  margin: 0;
}