#cart {
  min-height: 100vh;
  @include padding-y(6rem, 6rem);
  @include padding-x(8vw, 8vw);
}

.cart-container {
  display: grid;
  grid-template-columns: 1fr 36rem;
  gap: 4rem;

  .cart-container-bg {
    padding: 2rem;
  }
}

.empty-cart {
  @include centered;
}

.cart-item-box {
  margin-bottom: 2rem;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid var(--color-dark-gray);
}

.cart-item {
  @include margin-y(1rem, 1rem);
  gap: 2rem;

  &-image {
    flex: 0 0 150px;
    margin-left: 1rem;
  }

  &-details {
    flex: 1 1 auto;
    flex-wrap: wrap;
  }

  &-title {
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid var(--color-dark-gray);

    &-color {
      color: white;
      font-size: 1.1rem;
      letter-spacing: 1px;
      background: linear-gradient(115deg, rgba(255, 128, 0, 1) 0%, rgba(255, 40, 0, 1) 100%);
      padding: 4px 12px;
      border-radius: 25px;
      margin-left: 8px;
    }
  }

  &-price {
    font-weight: 600;
    color: var(--color-dark-green);
  }

  .cart-remove a {
    display: inline-block;
    margin-top: 1.2rem;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.summary-container-light {
  opacity: 0.75;
}

.feature-btn-submit {
  padding: .8rem 1.6rem;
  font-size: 1.1rem;
  width: 100%;
}

.addons-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid var(--bs-border-color);
  border-radius: 4px;

  & .addons-img {
    border-radius: 0.8rem;
    object-fit: contain;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex: 1 1;   
  }
}

.addons-content-top {
  font-weight: 500;
}

.addons-content-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.quantity {
  display: flex;
  color: var(--color-text-light);
  border: 2px solid var(--color-light-gray);
  border-radius: 4px;
  overflow: hidden;
}

.quantity button {
  background-color: var(--white-color);
  border: none;
  cursor: pointer;
  font-size: 18px;
  width: 30px;
  height: auto;
  text-align: center;
  transition: background-color 0.2s;
}

.quantity button:hover {
  background-color: var(--color-light-gray);
}

.input-box {
  width: 32px;
  text-align: center;
  border: none;
  padding: 0 6px;
  font-size: 16px;
  outline: none;
}

/* Hide the number input spin buttons */
.input-box::-webkit-inner-spin-button,
.input-box::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .input-box[type="number"] {
//   -moz-appearance: textfield;
// }

@include devices(xl) {
  .addons-content-container {
    @include grid-cols(1, 1fr, 2rem);
  }
}

@include devices(lg) {
  #cart {
    @include padding-x(8vw, 8vw);
  }

  .cart-container {
    @include grid-cols(1, 1fr, 2rem);
    margin-top: 3rem;
  }
}

@include devices(sm) {
  #cart {
    @include padding-x(0,0);
  }
}