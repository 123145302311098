#drives-us-section {
  padding: 4vw;
}

.drivesUs {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 4rem;
  position: relative;

  .img-container {
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
      z-index: 80;
    }
  }

  &-caption {
    position: absolute;
    z-index: 90;
    left: 4%;
    top: 4%;
    width: 50vw;

    > h2 {
      font-size: 4rem;
      color: var(--white-color);
    }
  }

  &-card {
    margin-bottom: 6rem;
    border-radius: 0.8rem;
    color: var(--black-color);

    &-content {
      margin-top: 2vw;

      > h4 {
        font-size: 2.4rem;
      }

      > p {
        margin-top: 1rem;
        font-size: 1.4rem;
      }
    }
  }
}

@include devices(lg) {
  .drivesUs-caption {
    width: 70vw;

    & > h2 {
      font-size: 2.8rem;
    }
  }  
}

@include devices(md) {
  .drivesUs {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}