.order-field-wrap {
  display: grid;
  @include grid-cols(2, 1fr, 1rem);
}

.order-details {
  padding: 0 4rem;
  padding-bottom: 4rem;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
}

.paynow-btn {
  border-radius: 8px;
  color: var(--white-color);
  background-color: var(--color-dark-green);
}

.razorpay {
  padding: 1.4rem;
  border-radius: 8px;
  background-color: var(--color-light-gray);
  text-align: center;

  & > img {
    height: 36px;
  }
}

@include devices(xl) {
  .order-field-wrap {
    @include grid-cols(1, 1fr, 1rem);
  }
}