.prod-card {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem;
  border-radius: 1.6rem;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 40px -10px rgba(0, 64, 128, 0.2);
}

.round-btn {
  @include dimension(2rem, 2rem);
  border-radius: 2rem;

  &:not(:first-child) {
    margin-left: 0.8rem;
  }
}

.prod-listing-btn {
  width: 100%;
  border-radius: 0;
}

.prod-section-img {
  flex: 1;
  overflow: hidden;
  position: relative;
}