.faq-content-wrapper {
  gap: 6rem;
  text-align: center;
}

.faq-content {
  display: grid;
  @include grid-cols(2, 1fr, 4rem);

  &-block {
    text-align: left;
    max-width: 30vw;

    &_question {
      font-size: 2rem;
      font-weight: 600;
    }
  
    &_answer {
      font-size: 1.6rem;
      margin-top: 1rem;
    }
  }

}

@include devices(lg) {
  .faq-content-block {
    max-width: 40vw;
  }
}

@include devices(md) {
  .faq-content-block {
    max-width: 80vw;
  }
  .faq-content {
    @include grid-cols(1, 1fr, 2rem);
  }
}

@include devices(sm) {

}


