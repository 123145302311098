.cart-popup {
  position: fixed;
  top: 64px;
  right: 10px;
  width: 400px;
  max-height: 112px;
  border-radius: 8px;
  border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: var(--white-color);
  z-index: 999;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &-content-img {
    flex-shrink: 0;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    > img {
      object-fit: contain;
      padding: 8px;
      max-height: 110px;
    }
  }

  &-content-data {
    padding: 1.2rem;
    gap: 6px;
    max-height: 110px;
    background: #ead8ba;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.close-popup {
  font-size: 1.2rem;
  letter-spacing: normal;
  text-transform: none;
  padding: 0.8rem 1.2rem;
  background-color: var(--black-color);
  color: var(--white-color);
  border: none;
}

@include devices(sm) {
  .cart-popup {
    width: auto;
  }
}
