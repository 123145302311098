.whatsapp-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: transparent;
  cursor: pointer;
  z-index: 1000;

  img {
    display: block;
  }
}