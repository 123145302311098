.tnc-container {
  & > h1 {
    font-size: 8rem;
    margin-bottom: 4rem;
  }

  & > h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  & > p {
    margin-top: 2rem;
  }

  div > p {
    margin-top: 2rem;
  }
}