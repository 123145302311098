.custom-o-list {
  counter-reset: my-counter;
  list-style-type: none;
  padding: 0;
}

.wrp-container {
  & > h1 {
    font-size: 8rem;
    margin-bottom: 4rem;
  }
}

.c-list {
  counter-increment: my-counter;

  &::before {
    content: counter(my-counter) ".";
    font-weight: bold;
    margin-right: 5px;
  }
}

.wrp-sub-terms {
  margin-top: 3rem;

  & > h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  & > p {
    margin-top: 1.6rem;
  }
}