.custom-btn {
  color: var(--black-color);
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  border: 2px solid;
  border-radius: 3rem;
  padding: 1.6rem 3.6rem;
  background-color: var(--secondary-color);

  @include transition(all, 0.4s, ease);

  &:hover {
    color: var(--secondary-color);
    background-color: var(--black-color);
  }
}