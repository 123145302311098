@mixin flex($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin dimension($width: auto, $height: auto) {
  width: $width;
  height: $height;
}

@mixin centered {
  display: grid;
  place-items: center;
}

@mixin mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@mixin margin-x($marginLeft, $marginRight) {
  margin-left: $marginLeft;
  margin-right: $marginRight;
}

@mixin margin-y($marginTop, $marginBottom) {
  margin-top: $marginTop;
  margin-bottom: $marginBottom;
}

@mixin padding-x($paddingLeft, $paddingRight) {
  padding-left: $paddingLeft;
  padding-right: $paddingRight;
}

@mixin padding-y($paddingTop, $paddingBottom) {
  padding-top: $paddingTop;
  padding-bottom: $paddingBottom;
}

@mixin img-fit {
  @include dimension(100%, 100%);
  object-fit: cover;
}

@mixin bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin pseudo($content: "", $top: 0, $left: 0, $width: auto, $height: auto) {
  content: $content;
  position: absolute;
  top: $top;
  left: $left;
  @include dimension($width, $height);
  pointer-events: none;
}

@mixin bordered($borderWidth, $borderColor) {
  border: $borderWidth solid $borderColor;
}

@mixin flex-container($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin grid-cols($colsCount, $colsWidth, $gap) {
  grid-template-columns: repeat($colsCount, $colsWidth);
  gap: $gap;
}

@mixin grid-item($col-span, $row-span) {
  grid-column: span $col-span;
  grid-row: span $row-span;
}

@mixin grid-center() {
  display: grid;
  place-items: center;
}

@mixin transition($prop: all, $duration, $timingFn: ease) {
  transition: $prop $duration $timingFn;
}

@mixin toggleVisibility($visibility, $opacity) {
  visibility: $visibility;
  opacity: $opacity;
}

@mixin prefix($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

@mixin text-truncate() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin text-ellipsis-multiline() {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Adjust to desired number of lines
  text-overflow: ellipsis;
}

@mixin text-gradient($color1, $color2) {
  background-image: linear-gradient($color1, $color2);
  -webkit-background-clip: text;
  color: transparent;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}



// mixin for media-queries

@mixin devices($breakpoint) {
  @if $breakpoint==xxl {
      @media (max-width: 1400px) {
          @content;
      }
  }

  @if $breakpoint==xl {
      @media (max-width: 1200px) {
          @content;
      }
  }

  @else if $breakpoint==lg {
      @media (max-width: 992px) {
          @content;
      }
  }

  @else if $breakpoint==md {
      @media (max-width: 768px) {
          @content;
      }
  }

  @else if $breakpoint==sm {
      @media (max-width: 576px) {
          @content;
      }
  }
}