#order {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.order-form { 
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  gap: 6rem;
  @include padding-y(2rem, 2rem);
}

@include devices(lg) {
  .order-form { 
    grid-template-columns: 1fr;
    gap: 4rem;
  }
}