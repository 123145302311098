.product-carousel-container {
  position: relative;
  display: flex;
  align-items: center;
}

.prod-details_left-col {
  position: relative;

  .prod-details-heading {
    position: absolute;
    top: 1rem;
  }
}

.main-image-container {
  flex: 1;
}

.main-image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: var(--white-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}