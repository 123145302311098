#activities-section {
  @include padding-y(2rem, 6rem);
}

.activities {
  position: relative;
  margin-top: 6rem;

  &-heading h2{
    font-size: 6rem;
  }

  .img-first {
    max-width: 32rem;
  }

  .img-middle{
    margin: 0 2rem;
    max-width: 36rem;
  }
}

.activities-content {
  margin-top: 6rem;
}

.school-activities,
.corporate-activities {
  margin: 2rem;
  max-width: 540px;
  border: 0.8rem;
  border-radius: 1.6rem;

  & > img {
    border-radius: 0.8rem;
  }
}

@include devices(md) {
  #activities-section {
    @include padding-x(4vw, 4vw);
  }

  .activities-heading h2 {
    font-size: 4rem;
  }
}