.leadership-container {
  text-align: center;
  
  &-content {
    display: grid;
    @include grid-center;
    @include grid-cols(1, 1fr, 4rem);

    &_img {
      display: flex;
      gap: 4rem;
    }
  }
}

.founder-details {
  &-name,
  &-post {
    font-size: 1.6rem;
  }
}

.founder-img {
  filter: grayscale(100%);
}