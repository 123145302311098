.product-wrap {
  display: grid;
  grid-template-columns: repeat(3, min(1fr));
  gap: 2rem;

  .product-card { 
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.product_card {
  position: relative;
  background: var(--white-color);
  text-align: center;
  // max-width: 44rem;
}

.product-container {
  @include flex-container(column, center, center);
  gap: 5rem;
}

.product-title > h3 {
  font-size: 1.6rem;
}

.product-booking-btn {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1.2rem 1.6rem;
  color: var(--white-color);
  background-color: var(--color-light-green);
  border-color: var(--color-light-green);
  border-radius: 6rem;

  &:hover {
    color: var(--color-dark-green);
    background-color: transparent;
    border-color: var(--color-light-green);
  }
}

@include devices(lg) {
  .product-wrap {
    grid-template-rows: 2;
    grid-template-columns: repeat(2, 1fr);
  }
}

@include devices(md) {
  .product-wrap {
    @include grid-cols(1, 1fr, 3rem);
  }

  .product-title > h3 {
    font-size: 1.6rem;
  }
}