.footer-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.footer-copyright {
  @include grid-cols(2, 1fr, 4rem);
}

.footer-menu {
  color: var(--secondary-color);

  & > h2 {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
  }

  &-list > li{
    margin-top: 1.6rem;
  }
}

.footer-copyright {
  color: var(--secondary-color);
  margin-top: 3rem;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #FFFFFF1A;
}

@include devices(lg) {
  .footer-container {
    @include grid-cols(2, 1fr, 4rem);
  }
}

@include devices(sm) {
  .footer-copyright {
    @include grid-cols(1, 1fr, 4rem);
  }
}