.nf-container {
  height: calc(100vh - $nav-height);
  background-color: #f8f8f8;

  &-heading {
    font-size: 20rem;
  }

  &-message {
    font-size: 1.6rem;
    color: var(--color-text-dark);
  }

  &-link {
    text-decoration: 'none';
    color: '#1e90ff';
    font-size: 1.2rem;
  }
}