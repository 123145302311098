.navbar {
  @include flex(space-between, center);
  flex: 1;

  &-brand img {
    width: 18rem;
  }

  &-container {
    @include padding-x(2vw, 2vw);
  }
}

.cart-and-toggler-container {
  display: flex;
  align-items: center;
}

#nav {
  z-index: 9999;
}

.navbar-nav {
  gap: 2.4rem;
  
  @include devices(lg) {
    gap: 2rem;
    padding: 2rem 0;
  }
  
  &_actions {
    font-size: $nav-font-size;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    transition: color .5s all;
  }

  &_actions:hover {
    color: var(--white-color);
  }

  &_action {
    position: relative ;

    .cart-length {
      background-color: red;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      position: absolute;
      top: -10px;
      left: 16px;

      h6 {
        color: var(--white-color);
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-top: 2px;
      }
    }
  }
}

.order-now {
   background-color: var(--white-color);
   padding: 0.8rem 2.4rem;
   font-size: 1.2rem;
   font-weight: 600;
   letter-spacing: 1px;
   text-transform: uppercase;
   border-radius: 2rem;
}

.test-ride-btn {
  color: var(--white-color);
  font-weight: bold;
  letter-spacing: 0;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to right, #11998e , #38ef7d);
  box-shadow: 0 0 2px 1px rgb(48, 239, 70);
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.dropdown-menu {
  --bs-dropdown-border-color: none !important;
}
