.heading {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
}

.sub-heading {
  > h2 {
    font-size: 6rem;
    font-weight: 600;
    line-height: 1.1;
  }
}

@include devices(xl) {
  .sub-heading {

    > h2 {
      font-size: 4rem;
    }
  }
}