.feature-product-img {
  height: 40vw;
}

.feature-section-content {
  > .content {
    text-align: center;
  }
}

.feature-btn {
  color: var(--white-color);
  background-color: var(--black-color);
  border-color: var(--black-color);

  &:hover {
    color: var(--black-color);
    background-color: transparent;
    border-color: var(--black-color);
  }
}

.features-wrapper {
  display: grid;
  grid-template-columns: .75fr 1.25fr;
  gap: 6rem;
}

.features {
  display: grid;
  @include grid-cols(2, 1fr, 3rem);
  grid-template-rows: 1fr 1fr;
  
  .features-item {
    display: grid;

    &_title span{
      font-size: 1.3rem;
      color: rgba($color: var(--black-color), $alpha: 0.2);
      font-weight: 700;
      text-transform: uppercase;
    }

    &_content {
      font-size: 1.3rem;
    }

    &_feature {
      font-size: 2rem;
      font-weight: 700;
    }
  }
}

@include devices(xl) {
  .features-wrapper {
    grid-template-columns: 1fr;
  }
}

@include devices(md) {
  .features {
    @include grid-cols(1, 1fr, 2rem);
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  .feature-section-content {
      max-width: 100vw;
  }
}