// #product-detail-section {
//   height: calc(100vh - 8rem);
// }

.details-and-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.product-detail-container {
  display: grid;
  grid-template-columns: 1fr 527px;
  height: 100%;
}

.prod-details_left-col {
  @include flex-container(column, space-evenly, center);
}

.prod-details-heading {
  font-size: 1.3rem;
  color: var(--white-color);
  padding: 0.8rem 1.6rem;
  border-radius: 10rem;
  background-color: var(--black-color);
  display: inline-flex;
}

.prod-details_short-description{
  position: absolute;
  bottom: 1rem;
  display: inline-flex;
  justify-content: space-evenly;
  min-width: 40rem;
  padding: 0.8rem 1.6rem;
  border-radius: 10rem;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
  backdrop-filter: blur(20px);

}

.short-description-info {
  p {
    font-size: 1.3rem;
  }

  h4 {
    font-weight: 600;
  }
}

.prod-details_right-col {
  height: calc(100vh - 6rem);
  top: 6rem;
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
}


//Single selection card

.single-selection-card {
  justify-content: flex-start;
  gap: 2rem;
}

.btn-wrapper {
  cursor: pointer;
  border-radius: 0.8rem;
  padding: .8rem;
  @include dimension(10rem, 7rem);
  @include flex(center, center);
}

.pd-round-btn {
  border-radius: 2.4rem;
  @include dimension(4rem, 4rem);

  :not(:last-child) {
    margin-left: 1rem;
  }
}

.product-details {
  @include padding-x(4rem, 4rem);
  @include padding-y(6rem, 6rem);

  &-heading {
    font-size: 3.6rem;
  }

  &-info {
    font-size: 1.4rem;
  }
}

@include devices(xxl) {
  .details-and-image {
    grid-template-columns: 1fr;
  }
}

@include devices(lg) {
  .product-detail-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .prod-details_right-col {
    height: auto;
  }
}
